import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogPost from "../components/blogPost";

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`

const BlogPage = ({ location, data: { allMarkdownRemark: { edges }}}) => (
  <Layout>
    <SEO 
    title="scriptday blogs | Ruby on Rails and React Development Company"
    url={location.pathname}
    keywords={
      [
        `scriptday blog`,
        `Ruby on Rails blog`,
        `Ruby on Rails`,
        `Ruby on Rails Development Company`,
        `Ruby on Rails Consulting Company`,
        `Ruby blog`,
        `Ruby`,
        `Ruby Consulting Company`,
        `Ruby Development Company`,
        `ReactJS Consulting Company`,
        `ReactJS Development Company`,
      ]
    }
    />
    <ul className="divide-y divide-gray-200 mt-10 px-5 md:px-10 lg:px-40">
  {
    edges.map((blog)=> {
      const { id } = blog.node;
      const { title, date, slug } = blog.node.frontmatter;
      return(
        <li key={id} className="p-6 mb-0">
          <BlogPost title={title} date={date} slug={slug} excerpt={blog.node.excerpt}></BlogPost>
        </li>
      )
    })
  }
  </ul>
  </Layout>
);

export default BlogPage;
