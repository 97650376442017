import React from "react";
import { Link } from "gatsby";

export default function BlogPost({ date, title, slug, excerpt}) {
    return(
      <article className="md:flex flex-col">
        <div className="md:w-1/4 text-gray-500 pb-2">{date}</div>
        <div className="md:w-3/4">
          <h3 className="text-2xl leading-8 font-bold tracking-tight">
            <Link to={slug}>{title}</Link>
          </h3>
          <p>
            {excerpt}
          </p>
          <p className="text-blue-600">
            <Link to={slug}>Read more →</Link>
          </p>
        </div>
      </article>
    );
}